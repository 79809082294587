import React,{useState, useEffect , useRef} from 'react'
import styled from 'styled-components';
import {Accordion, Card, Button, Table, Row, Col} from 'react-bootstrap';
import EdiText from 'react-editext'
import { FaPlus, FaPencilAlt, FaWindowClose, FaCheck, FaSearch, FaGripVertical, FaTrashAlt } from "react-icons/fa";
import { updateContactInfo, updateProjectOwner, removeContact } from '../../graphql/queries';
import { useSelector, useDispatch } from "react-redux";
import PEdit from '../../assets/p-edit.svg'
import Cancel from '../../assets/cancel.svg'
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import makeAnimated from 'react-select/animated';
import GeneralPopup from '../Modals/GeneralPopup';
import Sync from "../../assets/sync.gif";
const animatedComponents = makeAnimated();

export default function PropertyOwnerContact(props) {
    const {consoleLog} = require('../commonFunctions.js');
    const dispatch = useDispatch();
    consoleLog("PropertyOwnerContact props: ", props);

    const [contact, setContact] = React.useState(props.contact_info);
    const [first_name, setFirstName] = React.useState(contact.first_name);
    const [last_name, setLastName] = React.useState(contact.last_name);
    const [email, setEmail] = React.useState(contact.email);
    const [phone, setPhone] = React.useState(contact.phone);
    const [showAddNewContactLabel, setShowAddNewContactLabel] = React.useState(false);
    const [stData, setStData] = React.useState(useSelector((state) => state.templateStructure));

    const [synching, setSynching] = useState(false);
    const showSynching = async () => {
        setSynching(true);
        await new Promise((resolve) => setTimeout(resolve, 2000));  // Simulate save delay
        setSynching(false);
    };
    
    useEffect(() => {
        
        if(contact.contact_id == props.owner_contact_id){
            let owner_info = {'name': props.contact_info.first_name + " " + props.contact_info.last_name, 'email': props.contact_info.email, 'phone': props.contact_info.phone};
            props.onChangePropertyOwner(contact.contact_id, JSON.stringify(owner_info));
        }
        setContact(props.contact_info);
    }, [props.contact_info])

    const [modalIsOpen, setIsOpen] = React.useState(false);
    function openModal() {
        setIsOpen(true);
    }
    function closeModal() {
        setIsOpen(false);
    }

    async function removeContactInfo() {
        consoleLog("remove_contact", "()")    
        const contactId = contact.contact_id;
        showSynching();
        const remove_contact = await API.graphql({
            query: removeContact,
            variables: {
                contact_id: contact.contact_id
            }
        })
        consoleLog("remove_contact: ", remove_contact)
        props.onContactDelete(contactId);
        closeModal();
    }

    function removeContactInfoModal() {
        consoleLog("removeContactInfo: ", contact.contact_id);
        openModal();
    }

    function EditContactInfo(){
        setShowAddNewContactLabel(!showAddNewContactLabel);
    }

    async function setPropertyOwner(){
        let owner_info = {'name': contact.first_name + " " + contact.last_name, 'email': contact.email, 'phone': contact.phone};
                
        if(props.doc_id) {
            consoleLog("setPropertyOwner contact_id: ", contact.contact_id);
            showSynching();
            const update_project_owner = await API.graphql({
                query: updateProjectOwner,
                variables: {
                    owner_contact_id: contact.contact_id,
                    doc_id: props.doc_id
                }
            });
            consoleLog("setPropertyOwner update_project_owner: ", update_project_owner);

            let newData = stData;
            newData.owner_contact_id = contact.contact_id;

            let poi = {};
            poi.owner_contact_id = contact.contact_id;
            poi.name = contact.first_name + " " + contact.last_name;
            poi.email = contact.email;
            poi.phone = contact.phone;
            consoleLog('setPropertyOwner poi>>>',poi);

            newData.property_owner_info = JSON.stringify(poi);

            consoleLog("setPropertyOwner newData: ", newData);

            setStData(newData);
            dispatch({ type: "TEMPLATE_STRUCTURE", value: newData });
            props.updateDocOnElasticSearch();

            if(update_project_owner){
                props.onChangePropertyOwner(contact.contact_id, JSON.stringify(owner_info));
            }
        }else {
            props.onChangePropertyOwner(contact.contact_id, JSON.stringify(owner_info));
        }
    }

    const first_name_ref = useRef(0);
    const last_name_ref = useRef(0);
    const email_ref = useRef(0);
    const phone_ref = useRef(0);

    async function savePropertyOwnerContactInfo(){
        if(!first_name){
            consoleLog('createOwnerPropertyContac first_name', first_name);
            first_name_ref.current.setCustomValidity("Please enter First Name.");
            first_name_ref.current.reportValidity();
            return false;
        }
        if(!last_name){
            consoleLog('createOwnerPropertyContac last_name', last_name);
            last_name_ref.current.setCustomValidity('Please enter Last Name.');
            last_name_ref.current.reportValidity();
            return false;
        }
        if(!email){
            consoleLog('createOwnerPropertyContac email', email);
            email_ref.current.setCustomValidity('Please enter Email.');
            email_ref.current.reportValidity();
            return false;
        }
        let reg = email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        consoleLog('createOwnerPropertyContac reg',reg);
        if(!reg){
            email_ref.current.setCustomValidity('Please enter a valid Email.');
            email_ref.current.reportValidity();
            return false;
        }
        if(!phone){
            consoleLog('createOwnerPropertyContac phone, phone');
            phone_ref.current.setCustomValidity('Please enter Phone Number.');
            phone_ref.current.reportValidity();
            return false;
        }
        if(props.owner_contact_id == contact.contact_id){
            props.setOwnerName(first_name+' '+last_name);
            props.setOwnerPhone(phone);
            props.setOwnerEmail(email);
        }
        showSynching();
        const update_contact_info = await API.graphql({
            query: updateContactInfo,
            variables: {
                contact_id: contact.contact_id,
                first_name: first_name,
                last_name: last_name,
                email: email,
                phone: phone
            }
        })
        consoleLog("update_contact_info: ", update_contact_info)
        var contact_copy = {...contact};
        contact_copy.first_name = first_name;
        contact_copy.last_name = last_name;
        contact_copy.email = email;
        contact_copy.phone = phone;
        setContact(contact_copy);
        setShowAddNewContactLabel(false);
    }

    return (
        <>
        { modalIsOpen && <GeneralPopup 
            titleText="Remove Contact?"
            bodyText={<>Are you sure you want to <b>delete</b> this contact?</>}
            showModal={modalIsOpen}
            setShowModal={setIsOpen}
            confirm={removeContactInfo}
        /> }

        <PropertyOwnerContactSection>
            <Row className={`m-0 p-3 pl-4 pr-5 border-bottom ${(contact.contact_id == props.owner_contact_id) ? "contact_info_list_selected" : "contact_info_list" }`}>
                <div className="w-4 none">
                    { (contact.contact_id == props.owner_contact_id) &&
                        <FaCheck className="TickIcon"/>
                    }
                </div>
                <div className="w-96">
                    <div className="w-100 pl-3 spacing-block">
                        <div className="cursor-pointer field ref-id mt-1 float-right">
                            {props.owner_contact_id != contact.contact_id &&
                                <span className="detail_heading_label fs-small" onClick={removeContactInfoModal}><FaTrashAlt /></span>
                            }
                        </div>
                        <div className="p-0" onClick={setPropertyOwner}>
                            <div className="field last_name">
                                <span className="detail_heading_label fs-small">Name:</span>
                                <span className="detail_title_text ml-1 fs-smaller">{contact.first_name} {contact.last_name}</span>
                            </div>

                            <div className="field location">
                                <span className="detail_heading_label fs-small">Email:</span>
                                <span className="detail_title_text ml-1 fs-smaller">{contact.email}</span>
                            </div>

                            <div className="field ref-id">
                                <span className="detail_heading_label fs-small">Phone:</span>
                                <span className="detail_title_text ml-1 fs-smaller" >{contact.phone}</span>
                            </div>
                        </div>
                    </div>

                    <div className="w-100 pl-3 pt-3 edit-contact-spacing">
                        { !showAddNewContactLabel
                        ?
                            <h5 className="cursor-pointer heading" onClick={ EditContactInfo }>
                                <span className="mr-1"><img src={PEdit} style={{width:"23px" }} alt=""/></span>
                                Edit Contact Info
                            </h5>
                        :
                            <h5 className="cursor-pointer heading" onClick={ EditContactInfo }>
                                <span className="mr-1"><img src={Cancel} style={{width:"23px" }} alt=""/></span>
                                Cancel
                            </h5>
                        }
                    </div>

                    { showAddNewContactLabel &&
                        <div className="edit-inner-spacing w-100 pl-3 pt-3">
                            <Col xs={12} sm={12} md={6} className="p-0">
                                <input type="text" placeholder="First Name" className="setting-input" value={first_name} ref={first_name_ref} onInput={(e)=> setFirstName(e.target.value)}/>
                                <input type="text" placeholder="Last Name" className="setting-input" value={last_name} ref={last_name_ref} onInput={(e)=> setLastName(e.target.value)}/>
                                <input type="text" placeholder="Email" className="setting-input" value={email} ref={email_ref} onInput={(e)=> setEmail(e.target.value)}/>
                                <input type="text" placeholder="Phone" className="setting-input" value={phone} ref={phone_ref} onInput={(e)=> setPhone(e.target.value)}/>
                                <Button className="btn_save_contact m-0 mt-4 mb-3" onClick={savePropertyOwnerContactInfo}>SAVE CONTACT </Button>
                            </Col>
                            <Col xs={12} sm={12} md={6}></Col>
                        </div>
                    }
                </div>
            </Row>
        </PropertyOwnerContactSection>
        {synching && <img className="sync" src={Sync} />}
        </>
    )
}


const ModalSetting = styled.div`
    .modal_header{
        line-height: 1em;
        padding: .7em;
        background: #6a6e6f;
        color: white;
        font-weight: 600;
        text-align: left;
    }
    .modal_body{
        padding: 20px;
        font-size: medium;
    }
    .color-red{
        color: red;
    }
    .modal_footer{
        display: block !important;
        padding: 20px 0px !important;
    }
    .footer_btn{
        font-weight: 600;
        text-transform: uppercase;
        color: white;
        background: #ffffff;
        color: #000000;
        border-radius: 3px;
        padding: 1rem 7rem;
        box-sizing: border-box;
        cursor: pointer;
    }
    .btnUpdateQty{
        color: #ffffff;
        background: red;
    }
`

const customStyles = {
    content: {
        padding: '0px',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '30%',
        border: 'none',
        boxShadow: '0 0 15px rgb(0 0 0 / 40%'
    },
};

const PropertyOwnerContactSection = styled.div`
    cursor: pointer;
    .btn_save_contact{
        height: 50px;
        width: 50%;
        color: #212E33;
        font-family: Rajdhani;
        background-color: #F7941C !important;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.6px;
        line-height: 21px;
        text-align: center;
        outline:none;
        border:none;
        &:hover {
            background-color: #000 !important;
            color: #fff;
          }
    }
    
    .spacing-block {
        @media(max-width: 767px){
            padding-inline: 0 !important
        }
    }

    .none {
        @media(max-width: 767px){
            display: none;
        }
    }
    .TickIcon {
        position: relative !important;
        top: 20%;
        color: #00daa1;
        padding-left: 0;
    }
    .w-4{
        width: 2% !important;
    }
    .w-96{
        width: 98% !important;
    }
    
    .contact_info_list:hover{
        background: #f2f9f2;
    }
    .contact_info_list_selected{
        background: #c7f9ee;;
    }
    .border-bottom{
        border-bottom: 1px solid #bdbdbd;
    }
    .heading {
        display: flex;
        align-items: center;
        font-family: Rajdhani;
        font-weight: 600;
        letter-spacing: 0.53px;

        &: hover {
            color: #F7941C;
        }
        span {
            margin-right: 8px !important;
        }
    }
    .cursor-pointer{
        display: inline;
        cursor: pointer;
    }
    .edit-contact-spacing {
        @media(max-width: 767px){
            padding-left: 0 !important;
            padding-top: 0 !important;
        }
    }

    .edit-inner-spacing {
        @media(max-width: 767px){
            padding: 0 !important;
        }
    }
    .fs-inherit{
        font-size: inherit;
    }
    .fs-medium{
        font-size: medium;
    }
    .fs-small{
        font-size: small !important;
    }
    .fs-smaller{
        font-size: smaller !important;

        @media(max-width: 767px){
            span {
                margin-right: 6px !important;
            }
        }
    }
    .detail_heading_label {
        display: inline;
        line-height: 1;
        text-align: left;
        white-space: nowrap;
        vertical-align: baseline;
        color: #6a6e6f;
        border-radius: .25em;
        font-size: medium;
    }
    .detail_title_text{
        font-weight: 600;
        color: #414141;
        font-size: small;
    }
    .setting-input{
        height:40px;
        width:100%;
        outline:none;
        padding:10px;
        border-radius:4px;
        border:1px solid #B4BEBF;
        color:#212E33;
        font-style: normal;
        font-size:14px;
        margin-top:8px;
    }
`